<template>
  <div class="contents liveInfo" style="position: relative; height: calc(100vh - 50px);">
    <h1 style="height: 50px;line-height: 50px;margin-top:50px;">웨이포인트 목록</h1>
    <div class="liveRegister">
      <div class="register" style="width: 22%;margin-right: 20px;">
        <div class="mainBox wrap-detail-info">
          <h2 class="bold">상세정보</h2>
          <ul>
            <li>
              <span>{{$t("mission-data-title")}}</span>
              <h4>{{waypointInfo.title}}</h4>
            </li>
            <li>
              <span>{{$t("mission-data-setting-altitude")}}</span>
              <h4>{{ this.altitude ? this.altitude : "-"}}m</h4>
            </li>
            <li>
              <span>{{ $t("mission-data-way-registrant") }}</span>
              <h4>{{ waypointInfo.userName? waypointInfo.userName : "-" }}</h4>
            </li>
            <li>
              <span>{{$t("mission-data-setting-speed")}}</span>
              <h4>{{waypointInfo.autoFlightSpeed ? waypointInfo.autoFlightSpeed : "-"}}m/s</h4>
            </li>
            <li>
              <span>{{ $t("mission-data-way-dept") }}</span>
              <h4>{{ waypointInfo.userDepartmentName ? waypointInfo.userDepartmentName : "-" }}</h4>
            </li>
            <li>
              <span>{{$t("mission-data-setting-clear")}}</span>
              <h4>{{ waypointInfo.finishedActionType ? waypointInfo.finishedActionType : "-" }}</h4>
            </li>
          </ul>
        </div>

        <!--드론/멤버/포인트 테이블-->
        <div class="tableWrap one wrap-detail-table">
          <h2>웨이포인트 정보</h2>
          <div class="tableBox" style="height: 410px; overflow-y: auto;max-height: 410px;">
            <div class="wrap-detail-point-info" v-for="data in waypointLocationList " :key = "data.index" @click="handleSelectEl(data)" :class="{ active: (selectData != null &&  (selectData.index ==  data.index))}">
              <div class="wrap-detail-point-title">
                <h2><span>{{data.index + 1}}</span>번 포인트</h2>
                <ul>
                  <li>
                    <span>{{ $t("mission-data-lat") }}</span>
                    <h4>{{data.lat.toFixed(7) }}</h4>
                  </li>
                  <li>
                    <span>{{ $t("mission-data-long") }}</span>
                    <h4>{{data.lng.toFixed(7) }}</h4>
                  </li>
                </ul>
              </div>
              <ul>
                <li>
                  <span>{{ $t("mission-data-alt") }}</span>
                  <h4>{{data.altitude }}</h4>
                </li>
                <li>
                  <span>{{ $t("mission-data-vel") }}</span>
                  <h4>{{waypointInfo.autoFlightSpeed }}</h4>
                </li>
                <li>
                  <span>{{ $t("mission-data-angle") }}</span>
                  <h4>{{ getGimbalTypeShow(data.gimbalType) }}</h4>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="buttonWrap" style="display: flex; margin-top: 16px; justify-content: center;">
          <button class="point medium" style="width: 32.3333%;height:40px; background:#0080FF;border-color:#0080FF;border-radius:5px;font-weight:400;"  @click="backToMissionList">
            {{ $t("btn-mission-list") }}
          </button>
          <button class="medium margin6" @click="handleUpdate" style="width: 32.3333%;height:40px; border-color:#ddd;background:#ffffff;color:#969696; font-weight:400;border-radius:5px;">
            {{ $t("btn-update") }}
          </button>
          <button class="medium margin6" @click="handleDelete" style="width: 32.3333%;height:40px; border-color:#ddd;background:#ffffff;color:#969696; font-weight:400;border-radius:5px;">
            {{ $t("btn-delete") }}
          </button>
        </div>
      </div>
      <div class="map" style="width: calc(78% - 45px);height: calc(100% + 100px);position: absolute;right: 0;bottom: 0" >
        <Map ref="map" style="width : 100%; height: 100%; z-index: 1;" :loadMapName="loadMap"/>
        <!-- <div class="remote-table">
          <div class="tableWrap one">
            <table>
                <colgroup>
                  <col style="width: 5%">
                  <col style="width: 20%">
                  <col style="width: 15%">
                  <col style="width: 5%">
                  <col style="width: 15%">
                  <col style="width: 5%">
                  <col style="width: 15%">
                  <col style="width: 5%">
                  <col style="width: 10%">
                </colgroup>
                <tr>
                  <th class="fixedHeader" style=" padding: 6px 15px; font-size: 1.3rem; font-weight: bold;">이름</th>
                  <th class="fixedHeader">디바이스</th>
                  <th class="fixedHeader">미션 요청</th>
                  <th class="fixedHeader">미션 확인</th>
                  <th class="fixedHeader">업로드 요청</th>
                  <th class="fixedHeader">업로드 확인</th>
                  <th class="fixedHeader">시작 요청</th>
                  <th class="fixedHeader">시작 확인</th>
                  <th class="fixedHeader">요청</th>
                </tr>
                <tr v-for="data in tempMissionAutoList " :key = "'auto'+ data.regUUID">
                  <td>{{data.userName}}</td>
                  <td>{{data.deviceName}}</td>
                  <td>{{data.missionRequestTime == null ? "" : moment(data.missionRequestTime).format("YYYY.MM.DD HH:mm") }}</td>
                  <td>{{data.missionCompleteStatus }}</td>
                  <td>{{data.uploadRequestTime  == null ? "" :  moment(data.uploadRequestTime).format("YYYY.MM.DD HH:mm") }}</td>
                  <td>{{data.uploadCompleteStatus}}</td>
                  <td>{{data.startRequestTime  == null ? "" :  moment(data.startRequestTime).format("YYYY.MM.DD HH:mm")}}</td>
                  <td>{{data.startCompleteStatus}}</td>
                  <td>
                    <button class="point" style="font-size: 10px;" :disabled="!data.isBtn" @click="missionAutoRequest(data.btnType, data.regUUID)">
                      {{data.btnName}}
                    </button>
                  </td>
                </tr>
              </table>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<style>
.wrap-detail-info{border-radius: 5px;padding: 15px; margin-bottom: 15px;}
.wrap-detail-info > h2{font-size: 1.4rem;margin-bottom: 10px;}
.wrap-detail-info > ul{display: flex;flex-direction: column;margin: 0;padding-bottom: 10px;}
.wrap-detail-info > ul > li{display: flex;justify-content: space-between;align-items: center;border: 0;padding: 0;margin: 0;margin-bottom: 10px;}
.wrap-detail-info > ul > li:last-child{margin-bottom: 0;}
.wrap-detail-info > ul > li > span{background: #eee;width: 100px;text-align: center;padding: 3px;font-size: 1.2rem;border-radius: 10px;color: #969696;}
.wrap-detail-info > ul > li > h4{font-size: 1.2rem;font-weight: 500;color: #454545;}

.wrap-detail-table{padding: 15px;background: #fff;border-radius: 5px;}
.wrap-detail-table > h2{font-size: 1.4rem;margin-bottom: 10px;font-weight: bold;}
.wrap-detail-point-info{border: 1px solid #ddd;border-radius: 5px;padding: 10px 15px;margin-bottom: 10px;}
.wrap-detail-point-title{display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #ddd;margin-bottom: 10px;}
.wrap-detail-point-title > h2{font-size: 1.4rem;font-weight: bold;}
.wrap-detail-point-title > h2 > span{color: #0080FF;}
.wrap-detail-point-title > ul{display: flex;flex-direction: column;width: 60%;}
.wrap-detail-point-title > ul > li{display: flex;justify-content: space-between;align-items: center;border: 0;padding: 0;margin-bottom: 5px;}
.wrap-detail-point-title > ul > li > span{font-size: 1.2rem;color: #969696;}
.wrap-detail-point-title > ul > li > h4{font-size: 1.2rem;font-weight: 500;}

.wrap-detail-point-info > ul{display: flex;justify-content: space-between;}
.wrap-detail-point-info > ul > li{padding: 0;border: 0;margin: 0;}
.wrap-detail-point-info > ul > li > span{background: #eee;color: #969696;padding: 2px 10px;border-radius: 10px;font-size: 1.1rem;}
.wrap-detail-point-info > ul > li > h4{font-size: 1.2rem;text-align: center;padding-top: 5px;}

.remote-table{width: calc(50% + 0px);margin-left: 20px;position: absolute;z-index: 100;bottom: 30px;right: 10px;background: #ffffffeb;padding: 10px;border-radius: 5px;}

.wrap-detail-point-info:hover{border: 1px solid #6992F9;cursor: pointer;}
.wrap-detail-point-info.active{border: 1px solid #6992F9;}
.missionActive{background: #0080FF;}
</style>

<script>
import moment from "moment";
import Map from "@/components/map/Map";

import { fetchEvent } from "@/api/event";
import { format } from "@/mixins/format";
import {fetchWaypointInfo, fetchWaypointDelete, getWaypointGimbalPitchList} from "@/api/missionWaypoint"; //추후 삭제 
import { fetchWaypointLocationList} from "@/api/missionWaypointLocation";
//todo 삭제 -----------
import { fetchMissionAutoList, fetchMissionAutoRequest} from "@/api/missionAutoTemp";
//todo 삭제 -----------
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import MapDataType from "@/components/map/enum/MapDataType";
import {mapState} from "vuex";
import {getDefaultMapZoomLevel} from "@/util/common";
import {fetchMissionInfo, fetchMissionDelete, getMissionGimbalPitchList} from "@/api/mission";
import { fetchMissionLocationList} from "@/api/missionLocation";
fetchWaypointInfo, fetchWaypointDelete, getWaypointGimbalPitchList,fetchWaypointLocationList

export default {
  props: ["eventId","waypointId"],
  mixins: [format],
  components: { Map },
  data() {
    return {
      moment: moment,
      missionId: "",
      eventInfo: {},
      mapHeight: 0,
      waypointInfo : {},
      waypointLocationList: [],
      waypointLocationTotal: 0,
      devPositionHeight : 122,
      typeChangeMapShowHeight: 0,
      selectEl : null,
      selectData : null,
      loadMap : 'kakao',

      gimbalTypeList: getMissionGimbalPitchList(),
      altitude: null,
      finishedActionType : null,

      //todo 삭제 -----------
      tempMissionAutoTimer : null,
      tempMissionAutoList : [],
      //todo 삭제 -----------
    };
  },
  mounted() {
    this.$refs.map.isShowLine = true;
    this.getEvent();
    this.getWaypointInfo();
    this.initMapData(this.currentLang);

    this.tempMissionAutoTimer = setInterval(() => {
      this.missionAutoList();
    }, 1000);

  },
  created() {
    this.eventId = this.$route.query.eventId;
    this.waypointId = this.$route.query.missionId;

    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 15 });

    this.mapHeight = window.innerHeight - this.devPositionHeight;
    this.typeChangeMapShowHeight = this.mapHeight * 0.5625;
    window.addEventListener('resize', this.handleResize);
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
  },
  computed: {
    ...mapState({
      currentLang: state => state.store.currentLang,
    }),
  },
  methods: {
    //todo 삭제 ------------
    missionAutoRequest(type, id) {
      var data = {
        regUUID : id,
      };

      if(type == "mission") {
        data.missionId  = this.waypointId;
      }

      fetchMissionAutoRequest(type, data).then((res) => {
        console.log(res)
      });
    },
    missionAutoList() {
      var data = {
        eventId : this.eventId
      }
      fetchMissionAutoList(data).then((res) => {
        if(res.data.result == 0 ) {
          res.data.data.forEach((item) => {
            var btnName = null;
            var btnType = null;
            var isBtn = false;

            if(item.missionRequestTime == null) {
              btnName = "미션 전달";
              btnType = "mission"
              isBtn = true;

            } else {

              if(item.missionCompleteStatus != "Success") {
                btnName = item.missionCompleteStatus == "Fail" ? "전달 실패" : "전달 작업중.."
              } else {

                if(item.uploadRequestTime == null) {
                  btnName = "업로드";
                  btnType = "upload"
                  isBtn = true;
                } else {

                  if(item.uploadCompleteStatus != "Success") {
                    btnName = item.uploadCompleteStatus  == "Fail" ? "업로드 실패" : "업로드 작업중.."
                  } else {

                    if(item.startRequestTime == null) {
                      btnName = "미션 시작";
                      btnType = "start"
                      isBtn = true;
                    } else {
                      if(item.uploadCompleteStatus != "Success") {
                        btnName = item.startCompleteStatus  == "Fail" ? "업로드 실패" : "업로드 작업중.."
                      } else {
                        btnName = "시작 성공"
                      }
                    }
                  }
                }
              }
            }

            item.btnName = btnName;
            item.btnType = btnType;
            item.isBtn = isBtn;
          })
          this.tempMissionAutoList = res.data.data.filter((item) => {return (item.missionId == null || item.missionId == this.waypointId)});
        }
        //console.log(res)
      });
    },
    //todo ------------

    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }

      this.$nextTick(function(){
        this.getWaypointLocationList();
        

        if(this.eventInfo != null){
          this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
          if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
            this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
          } else {
            this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
          }
        }
      })
    },

    getGimbalTypeShow(findId){
      const index = this.gimbalTypeList.findIndex((data) => data.id == findId)
      if(index != -1) {
        return this.gimbalTypeList[index].show;
      } else {
        return "None";
      }
    },
    
    handleSelectEl(data) {
      var id = data.index;
      if(this.selectEl == id) {
        this.selectEl = null;
        this.selectData = null;
      } else {
        this.selectEl = id;
        this.selectData = data;
      }

      if(this.selectData != null) {
        this.$refs.map.onLngLatToMoveCenter(this.selectData.lng,  this.selectData.lat)
      }
    },
    getWaypointInfo(){

      fetchMissionInfo(this.waypointId).then((res) => {
        if (res.data.result == 0) {
          this.waypointInfo = res.data.data;
        }
      })

      // fetchWaypointInfo(this.waypointId).then((res) => {
      //   if (res.data.result == 0) {
      //     this.waypointInfo = res.data.data;
      //   }
      // })

    },
    getWaypointLocationList(){
      let params = {
        refId : this.eventId,
        missionId : this.waypointId
      };
    fetchMissionLocationList(params).then((res) => {
        if (res.data.result === 0) {
          this.waypointLocationTotal = res.data.data.total;
          this.waypointLocationList = res.data.data.content;

          this.altitude = this.waypointLocationList[0].altitude;

          let sort = this.waypointLocationList.sort((a,b) => {return a.index - b.index})
          let mapDataType = MapDataType.ID_WAYPOINT

          // this.$refs.map.onWaypointMarkerIdSort();
          // this.$refs.map.onWaypointLineIdSort();

          if( sort.length > 0) {
            this.$refs.map.onLngLatToMoveCenter(sort[0].lng,  sort[0].lat)

            sort.forEach( (item ) => {
              let index = item.index + 1
              let mapMarkerData = new MapMarkerData();
              mapMarkerData.id = index;
              mapMarkerData.lng = item.lng;
              mapMarkerData.lat = item.lat;
              mapMarkerData.rotate = item.azimuth;
              mapMarkerData.name = index;

              if(this.$refs.map.onIsMarker(mapDataType, index ) ) {
                this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
              } else {
                this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
              }

            })

            this.createMapLine("way-point", sort);
          } else {
            this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
          }
        }
      })

      // fetchWaypointLocationList(params).then((res) => {
      //   if (res.data.result === 0) {
      //     this.waypointLocationTotal = res.data.data.total;
      //     this.waypointLocationList = res.data.data.content;

      //     this.altitude = this.waypointLocationList[0].altitude;

      //     let sort = this.waypointLocationList.sort((a,b) => {return a.index - b.index})
      //     let mapDataType = MapDataType.ID_WAYPOINT
      //     if( sort.length > 0) {
      //       this.$refs.map.onLngLatToMoveCenter(sort[0].lng,  sort[0].lat)

      //       sort.forEach( (item ) => {
      //         let index = item.index + 1
      //         let mapMarkerData = new MapMarkerData();
      //         mapMarkerData.id = index;
      //         mapMarkerData.lng = item.lng;
      //         mapMarkerData.lat = item.lat;
      //         mapMarkerData.rotate = item.azimuth;
      //         mapMarkerData.name = index;

      //         if(this.$refs.map.onIsMarker(mapDataType, index ) ) {
      //           this.$refs.map.onModifyMarkerAt(mapDataType, mapMarkerData);
      //         } else {
      //           this.$refs.map.onCreateMarker(mapDataType, mapMarkerData);
      //         }
      //       })
      //       this.createMapLine("way-point", sort);
      //     } else {
      //       this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
      //     }
      //   }
      // })
    },
    createMapLine(id, locationGroup){
      let mapDataType = MapDataType.ID_WAYPOINT
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      mapLineData.isShow = true;
      if(this.$refs.map.onIsLine(mapDataType, id)) {
        this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
      } else {
        this.$refs.map.onCreateLine(mapDataType, mapLineData);
      }
    },
    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
          this.eventInfo = res.data.data;
          this.memo = this.eventInfo.memo;
          if(this.eventInfo.status == "Close"){
            const elapsedMSec = this.eventInfo.closeDate.getTime() - this.eventInfo.openDate.getTime()
            this.elapsedDay = elapsedMSec / 1000 / 60 / 60 / 24;
          }
        }
      });
    },
    handleDelete() {
      let result = confirm(this.$t("alert-message-delete-mission-detail"));
      if (result == false) {
        return;
      } else {
        fetchMissionDelete(this.waypointId).then((res) => {
          if (res.data.result == 0) {
            alert(this.$t("alert-message-delete"));
            this.$emit("back-list")
            this.$router.push("Mission")
          }
        });
       
      }
    },
    handleUpdate() {
      let result = confirm(this.$t("alert-message-update-mission-detail"));
      if (result == false) {
        return;
      } else {
        this.$router.push({ name: "MissionUpdate" , query: {eventId : this.eventInfo.eventId, missionId: this.waypointId }})

      }
    },
    //페이지 리사이즈
    handleResize(){
      this.mapHeight = window.innerHeight - this.devPositionHeight;
      this.typeChangeMapShowHeight = this.mapHeight * 0.5625;
    },
    backToMissionList(){
      this.$router.go(-1);
    },
  },
  beforeDestroy() {
    //todo 삭제 -----------
    clearInterval(this.tempMissionAutoTimer);
    //todo 삭제 -----------
  }
  // 삭제는 SDFile delete fileId 사용
};
</script>
