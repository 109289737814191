import axios from "axios";
import {baseApiVersion, baseUrl, headers} from "../config/env";


export function fetchMissionAutoList(params) {
  return axios.get(baseUrl + baseApiVersion + `/mission/auto/list`, {
    headers,
    params,
  });
}

export function fetchMissionAutoRequest(type, data) {
  return axios.post(baseUrl + baseApiVersion + `/mission/auto/${type}/request`, data, { headers });
}
